
import React, { useState } from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer";
import NewFooter from "./pricing/footer";
import { ParallaxProvider } from 'react-scroll-parallax';
import Modali, { useModali } from "./modal"
import "../scss/style.scss"
import "../scss/layout.css"
import ContactModal from "./contact-modal";
import footerLeftVectorImg from "../images/footer-left-vector.svg"
import footerRightVectorImg from "../images/footer-right-vector.svg"
import { Link } from "gatsby"
import ReactGA from "react-ga"

const Layout = ({ children, headerFixed = true, simple, toggleExistingOrgModal, page, footerBanner, className, newFooter, freeImg = false, latestFooter}) => {
  const [contactModal, toggleContactModal] = useModali()

  const [toggle, setToggle] = useState(false)

  const handleClick = (e) => {
    setToggle(false);
  }
  const footerBannerClick = () => {
    ReactGA.event({
      category: 'Main page_30 day_Banner',
      action: 'Main page_30 day_Banner'
    });
  }
  return (
    <ParallaxProvider>
      <Header toggleExistingOrgModal={toggleExistingOrgModal} toggle={toggle} setToggle={setToggle} toggleContactModal={toggleContactModal} headerFixed={headerFixed} page={page} className={className} />
      <main onClick={handleClick}>{children}</main>
      {newFooter ? <NewFooter toggleExistingOrgModal={toggleExistingOrgModal} page='Solo Plan LP' freeImg={freeImg}/> : <Footer toggleExistingOrgModal={toggleExistingOrgModal} toggleContactModal={toggleContactModal} simple={simple} page={`${page} page`} latestFooter={latestFooter} />}
      {footerBanner &&
      <div className="footer-banner">
        <div className="text">
          Try LUCA Plus FREE for 30 days
        </div>
        <div className="flex">
          <img src={footerLeftVectorImg} className="" />
          <Link to={`/pricing`}
                className="btn font-medium"
                state={{isPromoCode: true}}
                onClick={footerBannerClick}>
            Get Started
          </Link>
          <img src={footerRightVectorImg} />
        </div>
      </div>}
      <Modali.Modal {...contactModal}>
        <ContactModal />
      </Modali.Modal>
    </ParallaxProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
