import React, {useEffect, useState} from "react";
import { Link } from "gatsby"
import PropTypes from "prop-types"
import classNames from "classnames";
import ReactGA from "react-ga";
import useWindowScrollPosition from "../hooks/window-scroll-position";
import Logo from '../images/logo.svg'
import newTagImg from '../images/icons/new_tag.png'
import Modali, { useModali } from "./modal"
import footerLeftVectorImg from "../images/new-pricing/top-left-vector.png"
import footerRightVectorImg from "../images/new-pricing/top-right-vector.png"
import activeChevronIcon from "../images/active-chevron-down.svg"
import chevronIcon from "../images/chevron-down.svg"
import receivableIcon from "../images/receivable-icon.svg"
import payableIcon from "../images/payable-icon.svg"
import soloIcon from "../images/solo-icon.svg"
import lucapayIcon from "../images/lucapay-icon.svg"
import demoIcon from "../images/demo-icon.svg"
import recordedIcon from "../images/recorded-demo.svg"
import mythsIcon from "../images/myths-icon.svg"
import partnerIcon from "../images/partner-icon.svg"
import strategicBusinessPartnerIcon from "../images/strategic-business-partner-icon.svg"
import nsipIcon from "../images/nsip-icon.svg"
import marketPlaceIcon from "../images/market-place.svg"
import peppolIcon from "../images/peppol-icon.svg"
import l1Icon from "../images/l1.svg"
import l2Icon from "../images/l2.svg"
import l3Icon from "../images/l3.svg"
import b1Icon from "../images/b1.svg"
import b2Icon from "../images/b2.svg"
import b3Icon from "../images/b3.svg"
import bRightArrowIcon from "../images/bright-arrow.svg"

const Header = ({ toggle, setToggle, toggleContactModal, headerFixed, page, className, toggleExistingOrgModal}) => {
  const [exampleModal, toggleExampleModal] = useModali()
  const [activeTab, setActiveTab] = useState(menuItem[0].menu)
  const [openDropdown, toggleDropdown] = useState(false)

  const position = useWindowScrollPosition({
    throttle: 300,
  });

  const toggleCls = classNames('navbar-toggler',
    { 'collapsed': !toggle }
  )
  const mobileMenuWrapperCls = classNames(
    { 'show': toggle }
  )

  const handleContactClick = (e) => {
    setToggle(false);
    toggleContactModal()
  }


  const handleToggleMenu = (e) => {
    setToggle(!toggle)
    toggleDropdown(false)
  }

  const navItemClick = (navItem, link) => {
    ReactGA.event({
      category: 'NAV_ITEM',
      action: `Clicked ${navItem} Nav Item of ${page} Page`
    });
    if(navItem === activeTab){
      toggleDropdown(!openDropdown)
    } else {
      toggleDropdown(true)
    }
    setActiveTab(navItem)
    // if(toggleExistingOrgModal && navItem === "Sign Up") {
    //   toggleExistingOrgModal();
    // } else
      if(link) {
      window.open(link, "_self")
    }
  }

  const siteHeaderCls = classNames("site-header",
    { 'site-header--nofix': !headerFixed && (position.y <= 0) },
    { 'header-fixed': headerFixed || (position.y > 0) },
    { 'new-header-wrap': page === 'Main' }
  )

  if (typeof window !== "undefined") {
    window.addEventListener('click', function(e) {
      if(!e.target.classList.contains("mobile-menu-item")) {
        if (!e.target.classList.contains("menu-title")) {
          if (!document.getElementById('main-menu').contains(e.target)) {
            toggleDropdown(false)
          } else {
            toggleDropdown(true)
          }
        }
      }
    });
  }
  return (
    <header className={`${siteHeaderCls} ${className}`}>

      <div className={'container'}>
        <div className={`header-inner ${page === 'Main' && 'new-header'}`}>
          <nav className="site-nav">
              <div className="main-page-logo">
                <a href="/" className="flex items-center sm:flex-row flex-col">
                  <img src={Logo} alt="logo" className="lucaplus-img sm:pr-3 pr-0 w-1/2" />
                  <div className="sm:text-brand text-neutral-3 sm:mt-0 mt-1 whitespace-no-wrap sm:ml-3 ml-0 text-14">
                    Simpler and Faster Business
                  </div>
                </a>
              </div>

            <div className={toggleCls} onClick={handleToggleMenu}>
              <span className="menu_toggle" >
                {toggle && openDropdown && <div className="header">{activeTab}</div>}
                <span className="hamburger">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
                <span className="hamburger-cross">
                  <span></span>
                  <span></span>
                </span>
              </span>
            </div>
            <div id="site-menu-wrapper" className="site-menu-wrapper">
              <div id="main-menu">
                <ul className="site-menu">
                  {menuItem.map(({menu, link, subMenu, header, className}) =>
                    <div>
                      <li onClick={() => navItemClick(menu, link)} className={`${activeTab === menu && "activee "} menu`}>
                        <div className="mr-1 menu-title">{menu}</div>
                        {/*{activeTab === menu ? <img src={activeChevronIcon} /> : <img src={chevronIcon} />}*/}
                       <img src={chevronIcon} />
                      </li>
                      <div id="sub-menu">
                        {openDropdown && menu === activeTab && subMenu &&
                        <li className={`sub-menu ${className}`}>
                        {Object.keys(subMenu).map((key, index) => (
                          <div className="border-right pr-5 mr-5">
                            {header &&
                            <div className="header">
                              {key}
                            </div>}
                            {subMenu[key].map((item) =>
                              <div className={`flex items-center item`} onClick={() => item.link && window.open(item.link, "_self")}>
                                {item.icon && <img src={item.icon} className="mr-4" />}
                                <div>
                                  <div className="text-15 font-medium text-brand text-left title">
                                    {item.title} {item.comingsoon ?
                                    <label className="coming-soon">Coming Soon</label>
                                    : ""}
                                  </div>
                                  {item.description && <div className="desc">{item.description}</div>}
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </li>}
                      </div>
                    </div>
                      )}

                  {/*<li onClick={() => navItemClick("Pricing")}>*/}
                  {/*  <a href="/pricing/" >Pricing</a>*/}
                  {/*</li>*/}
                  {/*<li onClick={() => navItemClick("Support")}><a href="https://lucaplus.zendesk.com/hc/en-us" >Support</a></li>*/}
                  {/*<li onClick={() => navItemClick("Blog")}>  <a href="https://blog.lucaplus.com/" >Blog</a></li>*/}
                  {/*/!* <li>  <a href="/industries" >Industries</a></li> *!/*/}
                  {/*<li onClick={() => navItemClick("About")}>  <a href="/about/" >About us</a></li>*/}
                  {/*<li onClick={() => navItemClick("Contact")}>  <a href="/contact/" >Contact</a></li>*/}
                  {/*<li onClick={() => navItemClick("Partner Program")}>  <a href="/partner/" className="relative partner-link" >Partner Program <img src={newTagImg} className="inline-block absolute new-tag" /></a></li>*/}
                  <li onClick={() => navItemClick("Login")} className="mr-0 menu"><a href={`${process.env.GATSBY_APP_URL}/login`} className="login-btn">Log In</a></li>
                  <li onClick={() => navItemClick("Sign Up")} className="sm:flex hidden items-center last menu">
                    <div>
                      <img src={footerLeftVectorImg} className="sm:w-7/12 w-4/6 ml-auto" />
                    </div>
                    <Link  to={`/sign-up?plan=combined&lucapay=true&fromHomePage=true`}>
                      <div
                        className="signup-btn focus:outline-none flex justify-center items-center cursor-pointer"
                        // onClick={() => toggleExistingOrgModal ? toggleExistingOrgModal() : window.open(`/einvoicing-australia`)}
                      >
                        Sign Up
                      </div>
                    </Link>
                    <div>
                      <img src={footerRightVectorImg} className="sm:w-7/12 w-4/6 mr-auto" />
                    </div>
                    </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div id="mobile-menu-wrapper" className={mobileMenuWrapperCls}>
        <ul className="mobile-menu site-menu">
         <div className="flex-1">
          {menuItem.map(({menu, link, subMenu, header, className}) =>
            <div className="h-100 border-top">
              {!openDropdown &&
              <li onClick={() => navItemClick(menu, link)} className={`${activeTab === menu && "active "} menu`}>
                <div className="mr-1 mobile-menu-item">{menu}</div>
                <img src={bRightArrowIcon} />
              </li>}
              {openDropdown && menu === activeTab &&
              <li className={`sub-menu ${className}`}>
                {subMenu && Object.keys(subMenu).map((key, index) => (
                  <div>
                    {header &&
                    <div className="header">
                      {key}
                    </div>}
                    {subMenu[key].map((item) =>
                      <div className={`flex items-center item`} onClick={() => item.link && window.open(item.link, "_self")}>
                        {item.icon && <img src={item.icon} className="mr-4" />}
                        <div>
                          <div className="text-15 font-medium text-brand text-left title">
                            {item.title} {item.comingsoon ?
                                  <label className="coming-soon">Coming Soon</label>
                                  : ""}
                          </div>
                          {item.description && <div className="desc">{item.description}</div>}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </li>}
            </div>
          )}
         </div>
          {openDropdown && <li onClick={() => toggleDropdown(false)}><a className="back-btn">Back</a></li>}
          {/*<li onClick={() => navItemClick("Pricing")}>*/}
          {/*  <a href="/pricing/" >Pricing</a>*/}
          {/*</li>*/}
          {/*<li onClick={() => navItemClick("Support")}>*/}
          {/*  <a href="https://blockledger.zendesk.com/hc/en-us" >Support</a>*/}
          {/*</li>*/}
          {/*<li onClick={() => navItemClick("Blog")}>*/}
          {/*  <a href="https://blog.lucaplus.com/" >Blog</a>*/}
          {/*</li>*/}
          {/*/!* <li>  <a href="/industries" >Industries</a></li> *!/*/}
          {/*<li onClick={() => navItemClick("About us")}>  <a href="/about/" >About us</a></li>*/}
          {/*<li onClick={() => navItemClick("Contact")}>  <a href="/contact/" >Contact</a></li>*/}
          {/*<li onClick={() => navItemClick("Partner Program")}>  <a href="/signup/" >Partner Program</a></li>*/}
          {!openDropdown && <li onClick={() => navItemClick("Login")}><a href={`${process.env.GATSBY_APP_URL}/login`} className="login-btn">Log In</a></li>}
          {/*<li onClick={() => navItemClick("Sign Up")}><a href="/einvoicing-australia/" className="nav-btn">Sign Up</a></li>*/}
        </ul>
      </div>
      <Modali.Modal {...exampleModal}>Hi, I'm a Modali!</Modali.Modal>
    </header >

  )
}

export default Header

const menuItem = [
  {
    menu: "Solutions",
    className: "solutions",
    header: true,
    subMenu: {
      "Solutions": [{
        title: "LUCA Get",
        description: "Get paid faster from your customers in full by offering them flexible monthly repayment options",
        icon: receivableIcon,
        comingsoon: true,
      },
        // {
        //   title: "LUCA Invoice Solo",
        //   description: "Cut costs & make substantial time savings by subscribing to LUCA Plus",
        //   icon: soloIcon,
        //   link: "/accounting-software-self-employed/"
        // },
        { title: "LUCA Pay",
          description: "Pay your supplier invoice in full and repay us in monthly instalments to ease cash flow.",
          icon: lucapayIcon,
          link: "https://www.lucapay.com",
        },
        { title: "PEPPOL E-invoicing",
          description: "Automate your invoicing and billing data entering process to save time and money.",
          icon: peppolIcon,
        },
      ],
      "HOW IT WORKS": [{
        title: "Book Your Demo Today",
        description: "Book a demo with our Customer Success Team members",
        icon: demoIcon,
        link: "https://meetings.hubspot.com/ray-wang/meeting-with-luca-plus"
      },
        {
          title: "Pre-recorded Demo",
          description: "Don’t have time to book a demo? Check our pre-recorded demo today",
          icon: recordedIcon,
          link: "https://www.youtube.com/playlist?list=PLu2owmRpUikG5VRssXC6N46lY1u2Y57ia"
        },
        {
          title: "E-invoicing Myths Busted ",
          description: "Learn everything you need to know to become e-invoicing ready via our eBook",
          icon: mythsIcon,
          link: "https://blog.lucaplus.com/resources/"
        },
      ],
      "Integrations": [
        { title: "Xero", link: "https://apps.xero.com/au/function/bills-expenses/app/luca" },
        { title: "Quickbooks", link: "https://www.lucaplus.com/QuickBooks/" },
        { title: "MYOB", link: "https://www.lucaplus.com/myob/" },
        { title: "PEPPOL", link: "https://peppol.eu/who-is-who/peppol-certified-aps/" },
        { title: "Others" },
      ],
    },
  },
  // { menu: "Pricing", link: "/pricing" },
  {
    menu: "Partners",
    className: "program",
    header: null,
    subMenu: {
      "Partner": [{
        title: "Accountants & Bookkeepers Partner Program",
        description: "Foster business growth for you and your clients by joining the LUCA Plus Partner Program",
        icon: partnerIcon,
        link: "/partner/"
      },
      {
        title: "Strategic Business Partner",
        description: "Make payment easier for your business customers and get paid on time.",
        icon: strategicBusinessPartnerIcon,
        link: "/stratergic-business-partner/"
      }
        ],
      "blb-partner": [
        // {
        //   title: "New Signup Incentive Program",
        //   description: "Earn up to $2,000* towards your business operational or marketing costs",
        //   icon: nsipIcon,
        //   link: "/nsip/"
        // },
        {
          title: "Business Finance & Loan Brokers",
          description: "Help your client to ease cash flow and get quicker deal via partner portal.",
          icon: nsipIcon,
          link: "/blb-partner/"
        },
        {
          title: "Market Place",
          description: "Connect with trusted suppliers to make business payments and accounting transactions easier, simpler and smarter.",
          icon: marketPlaceIcon,
          link: "/market-place/"
        }

      ],
    },
  },
  {
    menu: "Resources",
    className: "resources",
    header: true,
    subMenu: {
      "Learn": [{
        title: "Webinar, Podcast, eBooks & Infographics ",
        description: "Accessible resources for you and your business to watch, listen, read and share",
        icon: l1Icon,
        link: "https://blog.lucaplus.com/resources/"
      },
        {
          title: "LUCA Live Events",
          description: "Join our hybrid webinars and participate in our\nlive events ",
          icon: l2Icon,
          link: "https://blog.lucaplus.com/luca-live-events/"
        },
        {
          title: "Need Support?",
          description: "Accessible resources about our product, programs and FAQ’s",
          icon: l3Icon,
          link: "https://lucaplus.zendesk.com/hc/en-us"
        }
      ],
      "Blog": [{
        title: "LUCA Plus Blog ",
        description: "Read and share useful industry news, articles and exciting stories from our customers and team members",
        icon: b1Icon,
        link: "https://blog.lucaplus.com/"
      },
        {
          title: "LUCA Plus News",
          description: "Stay on top of the latest industry news, LUCA Plus product releases and partnerships",
          icon: b2Icon,
          link: "https://blog.lucaplus.com/category/news/"
        },
        {
          title: "Your Industry",
          description: "Learn how you can improve your cash flow and back office\nefficiency based on your industry",
          icon: b3Icon,
          comingsoon: true
        },
      ]
    },
  },
  {
    menu: "About",
    className: "about",
    subMenu: {
      "a1": [
        { title: "Our Story", link: "/about/" },
        { title: "Our Team", link: "/about/" },
        { title: "Contact Us", link: "/contact/" },
        { title: "Book a Live Demo", link: "https://meetings.hubspot.com/ray-wang/meeting-with-luca-plus" },
      ],
      "a2": [
        { title: "Blog", link: "https://blog.lucaplus.com/" },
        { title: "Linkedin", link: "https://www.linkedin.com/company/lucaplus/" },
        { title: "Facebook", link: "https://www.facebook.com/LucaPlusAU/" },
        { title: "Youtube", link: "https://www.youtube.com/channel/UC0652wQaMNRp1c9_I4ZdoAA" },
      ],
    },
  },
]

