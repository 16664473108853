import React, { useState } from "react"
import EmailImage from "../images/modal/email.svg"
import LocationImage from "../images/modal/location.svg"
import CallImage from "../images/modal/call.svg"
export default function ContactModal() {
  const mapUrl =
    "https://g.page/lucaplus"
  return (
    <div className="contact">
      <h1 className="contact__title underline-green2">We're Here to Help</h1>

      <div className="contact__box">
        <div className="contact__box__item">
          <a href="mailto:info@lucaplus.com">
            <img src={EmailImage} alt="email" />
          </a>
          <p>
            <a href="mailto:info@lucaplus.com">info@lucaplus.com</a>
          </p>
        </div>

        <div className="contact__box__item ">
          <a href={mapUrl} target="_blank" rel="noopener noreferrer">
            <img src={LocationImage} alt="location" />
          </a>
          <p>
            {" "}
            <a href={mapUrl} target="_blank" rel="noopener noreferrer">
              121 King Street, Melbourne, Victoria, 3000
            </a>
          </p>
        </div>

        <div className="contact__box__item">
          <a href="tel:+61 (0)3 90055308">
            <img src={CallImage} alt="call" />
          </a>
          <p>
            <a href="tel:+61 (0)3 90055308">+61 (03) 9005 5308</a>
          </p>
        </div>
      </div>
    </div>
  )
}
